import { useMicroCopy } from "./MicroCopy";

export function usePhoneByFacityType(faciltyType) {
  let phoneNumber = null;
  switch (faciltyType) {
    case "daycare":
      phoneNumber = useMicroCopy("day.care.phone", "");
      break;

    case "nursing-home":
      phoneNumber = useMicroCopy("nursing.home.phone", "");
      break;

    case "senior-residence":
      phoneNumber = useMicroCopy("senior.residence.phone", "");
      break;

    default:
      break;
  }
  return phoneNumber;
}
